import FastClick from 'fastclick'
import {sizeNavbar} from './navbar_functions'

let navbarHeight = undefined

const isMobileNav = () => $('.check-for-mobile').css('float') === 'none'
//const isCollapsible = () => $('main').height() > $(window).height()
const isCollapsed = () => isMobileNav() || $('body').hasClass('navbar-collapsed')
const isBelmont = ()  => window.name == 'belmont'
const isNhl = () => window.name == 'nhl'
const isCartSummaryPage = () => document.querySelector('.cart-edit-page')
const isStripeElementsWindow = () => $('main').hasClass('stripe-elements')

const isCollapsible = () => {
  if((isBelmont() || isNhl()) && isStripeElementsWindow() && isCartSummaryPage() && isMobileNav()) {
    return true
  } else {
    return $('main').height() > $(window).height()
  }
}

function updateCollapse() {
  if (!navbarHeight || !isCollapsible()) { return }

  const body = $('body')
  const collapsed = body.hasClass('navbar-collapsed')
  const toggle = (collapsed && (window.pageYOffset < navbarHeight)) ||
    (!collapsed && (window.pageYOffset >= navbarHeight))

  if (toggle) { body.toggleClass('navbar-collapsed') }
  sizeNavbar()
}


$(document)
  .on('turbo:load', function() {
    FastClick.attach(document.body)
    if ($('.navbar').is(':visible')) { navbarHeight = $('.navbar').height() }
    sizeNavbar()
  })

  .on('hide.bs.dropdown', '.navbar-nav', function(event) {
    if (isCollapsed()) {
      event.stopPropagation()
      event.preventDefault()
    }
  })

  .on('show.bs.dropdown', '.navbar-nav', function(event) {
    if (isCollapsed()) {
      $(event.target).siblings().toggleClass('child-expanded')
    }
  })

  .on('click', '.navbar-nav .dropdown.open > .dropdown-toggle', function(event) {
    if (isCollapsed()) {
      let parent = $(event.currentTarget).parent()
      parent.removeClass('open').siblings().toggleClass('child-expanded')
      event.stopPropagation()
      event.preventDefault()
    }
  })

  .on('click', '#search-button', () => $('#search').focus())

  .on('scroll click turbo:load', function(event) {
    if (!isMobileNav || !$.contains($('.navbar-collapse'), event.target)) {
      $('.navbar-collapse').collapse('hide')
      $('.navbar .open:not(.search-dropdown)').removeClass('open')
    }
  })

  .on('scroll', updateCollapse)

  .on('hidden.bs.collapse', '.navbar-collapse', function() {
    $('.navbar .child-expanded').removeClass('child-expanded')
    $('.navbar .open').removeClass('open')
  })

window.addEventListener('resize', sizeNavbar)
