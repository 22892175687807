<template>
  <div class="people-quantity">
    <div class="people-quantity">
      <h6
        v-if="staticQuantity && purchasable && viewOnly=='false'"
        class="deposit-qty"
      >
        {{ maxDepositQty }}
      </h6>
      <div
        v-else-if="purchasable && viewOnly=='false'"
        class="counter"
      >
        <span
          class="subtract"
          :class="disableSubtraction ? 'disable': '' "
          @click="updateQuantity(-1)"
        />
        <span class="number-of-rooms">
          {{ pkgQty }}
        </span>
        <span
          class="add"
          :class="disableAddition ? 'disable': '' "
          @click="updateQuantity(1)"
        />
      </div>
      <div
        v-else
        class="counter-disabled"
      />
      <a
        v-if="pkgInventory< 1 || validPkg =='false'"
        :href="ticketPath"
      >
        <div
          class="btn sold-out"
          disabled
        >
          <span>{{ $t('formPackage.soldOut') }}</span>
        </div>
      </a>
      <a
        v-else-if="viewOnly=='true'"
        :href="ticketPath"
      >
        <div
          class="btn"
        >
          <span>{{ $t('orders.view') }}</span>
        </div>
      </a>
      <div
        v-else-if="purchasable"
        class="btn add-to-cart"
        @click="addToBasket()"
      >
        <span class="add-to-basket-span">{{ $t('formPackage.addToCart') }}</span>
        <span class="add-span">{{ $t('formPackage.add') }}</span>
      </div>
      <div
        v-else
        class="btn disabled"
        disabled
      >
        <span>{{ $t('formPackage.soldOut') }}</span>
      </div>
    </div>

    <div class="errors">
      {{ errs }}
    </div>
  </div>
</template>

<script>

import Api from '../../shared/cart/cart_api'
import { mapActions, mapMutations } from 'vuex'

export default {

  data() {
    return {
      pkgQty: 1,
      maxQty:0,
      disableButton: false,
      errs: null,
      pkgId: null,
      pkgInventory: null,
      validPkg: false,
      viewOnly: false,
      ticketPath: null,
      ten_required: false,
      hotelBehavior: null,
      contractHotelsAvailable: 0
    }
  },

  computed: {
    disableSubtraction() {
      return this.pkgQty < 2 || this.ten_required
    },

    maxDepositPkgInventory(){
      if(this.order && this.order.max_deposit_package_quantity){
        return this.order.max_deposit_package_quantity
      } else {
        return 10
      }
    },
    disableAddition() {
      return this.pkgQty==this.pkgInventory || this.pkgQty == this.maxDepositPkgInventory || this.pkgQty>=10
    },
    hotelsAvailable() {
      if(this.hotelBehavior == 'contracted_only'){
        return this.contractHotelsAvailable > 0
      } else {
        return true
      }
    },
    purchasable() {
      return this.pkgInventory>=1 && this.validPkg=='true' && this.hotelsAvailable
    },
    staticQuantity(){
      return this.order && this.order.deposit_qty_exact_match
    },
    maxDepositQty(){
      if(this.order && this.order.max_deposit_package_quantity){
        if(this.pkgInventory >= this.order.max_deposit_package_quantity ){
          return this.order.max_deposit_package_quantity
        }
        else {
          return 'Insufficient Inventory'
        }
      }else {
        return 10
      }
    },
    order(){
      return this.$store.state.order.order
    },
    postBody() {
      return JSON.stringify({
        order_item: {
          package_id: this.pkgId,
          quantity: this.pkgQty,
        }
      })
    }
  },

  watch: {
    staticQuantity() {
      if(this.staticQuantity){
        this.pkgQty = this.order.max_deposit_package_quantity
      }
    }
  },


  mounted(){
    // debugger
    this.pkgId = this.$el.parentElement.parentElement.getAttribute('data-package-id')
    this.pkgInventory = this.$el.parentElement.parentElement.getAttribute('data-inventory')
    this.validPkg = this.$el.parentElement.parentElement.getAttribute('data-acceptance')
    this.viewOnly = this.$el.parentElement.parentElement.getAttribute('data-view-only')
    this.ticketPath = this.$el.parentElement.parentElement.getAttribute('data-ticket-path')
    this.hotelBehavior = this.$el.parentElement.parentElement.getAttribute('data-hotel-behavior')
    this.contractHotelsAvailable = parseInt(this.$el.parentElement.parentElement.getAttribute('data-contract-hotels-available'), 10)

    if(this.$el.parentElement.parentElement.getAttribute('data-ten-required') == 'true'){
      this.pkgQty = 10
      this.ten_required = true
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('cart', ['displayCart']),

    updateQuantity(value) {
      if(value == -1 && this.pkgQty > 1) { // subtract
        this.pkgQty += value
      } else if(value == 1  && this.pkgQty < this.pkgInventory){ // add
        this.pkgQty += value
      }
    },

    addToBasket() {
      if (this.disableButton) { return }

      this.addOrderItem()

    },
    addOrderItem(){
      this.disableButton = true
      this.errs = null

      Api.createOrderItem(this.postBody).then(item => {
        if (item.id) {
          this.getBackendOrder().then ( () => {
            if (item.ticket_and_hotel){
              window.location.href = `/cart/events/${item.event_id}/hotels`
            } else {
              this.displayCart()
            }
          })
        } else{
          this.errs = item.errors || this.$t('formPackage.error')
        }
        this.disableButton = false
      })
    }
  }
}
</script>
