// import FetchCall from '../../local_fetch_call'
import FetchCall from '../local_fetch_call'

export default {
  findCustomerDeposits() {
    let depositRedemption = document.getElementById('deposit-redemption')
    if(depositRedemption){
      FetchCall.fetchWithoutLocale( '/deposit_redemptions', 'GET', null).then(json =>  {
        json.deposits.forEach(deposit => {
          this.createButton(deposit, depositRedemption, json.btn_text)
        })
      })
    }
  },

  createButton(deposit, depositRedemption, btn_text){
    let btn = document.createElement('button')
    btn.innerHTML = btn_text
    btn.className = 'btn btn-secondary separated'
    btn.addEventListener('click', ()=> this.addDepositToCart(deposit) )
    depositRedemption.appendChild(btn)
  },


  addDepositToCart(deposit) {
    FetchCall.fetchWithoutLocale( '/deposit_redemptions', 'POST', JSON.stringify({deposit})).then (res => {
      if(res.added == true) {
        window.location.pathname = res.event_path
      }
    })
  }
}
