import VueLoader from '../../default/vue-loader'
import { store } from'../../default/store'

import SinglePackage from './single-package'
import SinglePackageSeatingChart from './single-package-seating-chart'

export default {
  AddToCart() {
    var els = Array.from(document.getElementsByClassName('add-to-basket-form'))

    if(els.length) {
      els.forEach(element => {
        VueLoader.loadVueComponent(SinglePackageSeatingChart, element.querySelector('.people-quantity'), store)
      })
    } else {
      els = Array.from(document.getElementsByClassName('people-quantity'))

      els.forEach(element => {
        VueLoader.loadVueComponent(SinglePackage, element, store)
      })
    }
  }
}
