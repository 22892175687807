$(document)
  .on('click', '.mobile-menu-toggle', function() {
    $('.mobile-header').toggleClass('mobile-header--active')
    $('.header-contained').toggleClass('-expanded')
  })

  .on('click', '.mobile-header__tab', function() {
    if(!$(this).hasClass('mobile-header__tab--link')) {
      $(this).addClass('mobile-header__tab--active')
    }

    $(this).siblings().removeClass('mobile-header__tab--active')
    let id = $(this).attr('aria-controls')
    if(id) {
      $('.mobile-header__content').addClass('hide')
      $('#' + id).removeClass('hide')
    }
  })

  .on('click', '.au-search-icon svg', function() {
    $('.nav-overlay').addClass('open')
    $('body').addClass('overflow')
  })

  .on('click', '.au-search-icon .au-close', function() {
    $('.nav-overlay').removeClass('open')
    $('body').removeClass('overflow')
  })

  .on('click', '.nav-overlay', function() {
    $('.nav-overlay').removeClass('open')
    $('body').removeClass('overflow')
  })

  .on('show.bs.dropdown', '.au-search-icon', function() {
    $('.nav-overlay').addClass('open')
    $('body').addClass('overflow')
    $('.search-wrapper').addClass('open')
  })

  .on('hide.bs.dropdown', '.au-search-icon', function() {
    $('.nav-overlay').removeClass('open')
    $('body').removeClass('overflow')
    $('.search-wrapper').removeClass('open')
  })


const hideHoverLine = () => {
  let hover_line = document.querySelector('#hover-line')
  Array.from(document.querySelectorAll('.header-inner ul .first-level')).forEach((elem) => {
    if(elem.parentNode.classList.contains('-active')) {
      if(!elem.parentNode.matches(':hover')){
        elem.parentNode.classList.remove('-active')
        hover_line.classList.remove('-active')
      }
      //if it contains it and not hovering on parentNode's ul element
    }
  })

  Array.from(document.querySelectorAll('.social-part .nav-item')).forEach((elem) => {
    if(elem.parentNode.classList.contains('-active')) {
      elem.parentNode.classList.remove('-active')
      hover_line.classList.remove('-active')
    }
  })
}

const showHoverLine = (elem) => {
  let hover_line = document.querySelector('#hover-line')
  elem.parentNode.classList.add('-active')
  hover_line.classList.add('-active')

  let width = elem.getBoundingClientRect().width
  let left = elem.getBoundingClientRect().left + window.pageXOffset

  hover_line.style.width = `${width}px`
  hover_line.style.left = `${left}px`
}

document.addEventListener('turbo:load', () => {

  Array.from(document.querySelectorAll('.header-inner ul .first-level')).forEach((elem) => {
    elem.addEventListener('mouseenter', () => {
      hideHoverLine()
      showHoverLine(elem)
    })

    elem.addEventListener('mouseleave', () => {
      hideHoverLine()
    })
  })

  Array.from(document.querySelectorAll('.primary-nav-wrapper__nested-parent')).forEach((elem) => {
    elem.addEventListener('mouseleave', () => {
      hideHoverLine()
    })
  })

  Array.from(document.querySelectorAll('.social-part .nav-item')).forEach((elem) => {
    elem.addEventListener('mouseenter', () => {
      hideHoverLine()
      showHoverLine(elem)
    })

    elem.addEventListener('mouseleave', () => {
      hideHoverLine()
    })
  })
})
