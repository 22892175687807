<template>
  <div>
    <div class="people-quantity">
      <div
        v-if="pkgInventory>=1 && validPkg==true"
        class="counter"
      >
        <span
          :class="[disableSubtraction ? 'disable' : '', 'subtract']"
          @click="updateQuantity(-1)"
        />
        <span class="number-of-rooms">
          {{ pkgQty }}
        </span>
        <span
          :class="[disableAddition ? 'disable' : '', 'add']"
          @click="updateQuantity(1)"
        />
      </div>
      <div
        v-else
        class="counter-disabled"
      />
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      pack: null,
      pkgQty: 1,
      maxQty:0,
      disableButton: false,
      errs: null,
      pkgId: null,
      pkgInventory: null,
      validPkg: false
    }
  },

  computed: {
    disableSubtraction() {
      return this.pkgQty < 2
    },
    disableAddition() {
      return this.pkgQty==this.pkgInventory
    }
  },

  mounted() {
    this.pack = this.$store.state.packages.selectedPackage

    if(this.pack) {
      this.pkgId = this.$store.state.packages.selectedPackage.id
      this.pkgInventory = Math.min(this.$store.state.packages.selectedPackage.inventory_on_site, 10)
      this.validPkg = this.$store.state.packages.selectedPackage['valid_package?']
      this.$store.commit('packages/updateValidGpePackage', this.validPkg)
    }
  },

  methods: {

    packageName(pack) {
      if (pack == null) { return }

      return pack.display_name
    },

    updateQuantity(value) {
      if ( value == -1 && this.pkgQty != 1  || value == 1 && this.pkgInventory > this.pkgQty) {
        if(this.$store.state.packages.selectedPackage.display_name == this.packageName(this.pack)){
          this.pkgQty += value
          this.$store.commit('packages/updatePackageQty', this.pkgQty)
        }
      }
    }
  }
}
</script>
