import VueLoader from '../../../default/vue-loader'
import { store } from'../../../default/store'


import NavCart from './nav_cart'

export default {

  addCartToNavbar() {
    return VueLoader.loadVueComponent(NavCart, document.getElementById('navCartForm'), store)
  }
}
